// const { Thumbs } = require("swiper/modules");

// Story Swiper (_partials/entry/storySlider.twig)

// define variable for: the element .story-Swiper with data-loop attribute. If the data-loop attribute is present, the loop is true, otherwise it is false.
const storySwiper = document.querySelector(".Story-Swiper");
const loop = storySwiper?.dataset.loop == 1 ? true : false;
// console.log(loop);
if (storySwiper !== null && storySwiper !== undefined) {
  const storySwiper = new Swiper(".Story-Swiper", {
    direction: "horizontal",
    loop: loop,
    speed: 200,
    slidesPerView: 1.1,
    breakpoints: {
      // default
      375: {
        slidesPerView: 1.1,
        coverflowEffect: {
          scale: 0.8,
        },
      },
      // sm: "576px",
      576: {
        slidesPerView: 1.2,
        coverflowEffect: {
          scale: 0.9,
        },
      },
      // md: "768px",
      768: {
        slidesPerView: 1.2,
      },
      // lg: "992px",
      992: {
        slidesPerView: 1.15,
        coverflowEffect: {
          scale: 0.9,
        },
      },
      // xl: "1200px",
      1200: {
        slidesPerView: 1.2,
      },
      // "2xl": "1600px",
      1600: {
        slidesPerView: 1.3,
      },
      // "3xl": "1920px",
      1920: {
        slidesPerView: 1.3,
      },
      // "4xl": "2300px",
      2300: {
        slidesPerView: 1.3,
      },
    },
    effect: "coverflow",
    grabCursor: true,
    coverflowEffect: {
      scale: 0.9,
      slideShadows: false,
      rotate: 0,
      stretch: 100,
    },
    centeredSlides: true,
    pagination: {
      enabled: false,
    },
    navigation: {
      nextEl: ".slider-button-next",
      prevEl: ".slider-button-prev",
    },
    scrollbar: {
      enabled: false,
    },
  });
}

// --------------------------------------------------------------------------------------------------
// Three Image Gallery Swiper (_partials/entry/threeImageGallery.twig)  // Mobile Only

const threeImageGallerySwiperEl = document.querySelector(".Gallery-Swiper");
if (
  threeImageGallerySwiperEl !== null &&
  threeImageGallerySwiperEl !== undefined
) {
  const smallSlider = new Swiper(".Gallery-Swiper", {
    direction: "horizontal",
    rewind: true,
    slidesPerView: 1,
    navigation: {
      nextEl: ".gallery-Swiper-button-next",
      prevEl: ".gallery-Swiper-button-prev",
    },
  });
  const activeSlideIndex = document.getElementById("activeSlideIndex");
  if (activeSlideIndex) {
    activeSlideIndex.innerText = smallSlider.activeIndex + 1;
  }

  smallSlider.on("activeIndexChange", function () {
    activeSlideIndex.innerText = smallSlider.activeIndex + 1;
  });
}

// --------------------------------------------------------------------------------------------------
// Gallery Swiper (_partials/entry/gallerySlider.twig) // Mobile Only
const smallgallerySwiperEl = document.querySelector(".Gallery-small-Swiper");
// console.log('smallgallerySwiperEl:', smallgallerySwiperEl);
if (smallgallerySwiperEl !== null && smallgallerySwiperEl !== undefined) {
  const smallgalleryloop = smallgallerySwiperEl?.dataset.loop == 1 ? true : false;
  // console.log('smallgalleryloop:', smallgalleryloop);

  const gallerySmallSwiper = new Swiper(".Gallery-small-Swiper", {
    direction: "horizontal",
    loop: smallgalleryloop,
    slidesPerView: 1,
    navigation: {
      nextEl: ".gallery-Swiper-small-button-next",
      prevEl: ".gallery-Swiper-small-button-prev",
    },
  });

  gallerySmallSwiper.on('init', function() {
    // console.log('gallerySmallSwiper initialized');
  });

  const activeSlideIndex2 = document.getElementById("activeSlideIndex");
  if (activeSlideIndex2) {
    activeSlideIndex2.innerText = gallerySmallSwiper.activeIndex + 1;
  }

  gallerySmallSwiper.on("activeIndexChange", function () {
    activeSlideIndex2.innerText = gallerySmallSwiper.activeIndex + 1;
  });

  gallerySmallSwiper.init();
} else {
  // console.log('smallgallerySwiperEl not found or undefined');
}


// ---------------------------------------------
// Gallery Swiper (_partials/entry/gallerySlider.twig) // Thumbs only
const thumbsSwiperEl = document.querySelector(".Thumbs-Gallery-Slider");
let thumbsSwiper = null;
if (thumbsSwiperEl !== null && thumbsSwiperEl !== undefined) {
  thumbsSwiper = new Swiper(".Thumbs-Gallery-Slider", {
    direction: "horizontal",
    spaceBetween: 20,
    slidesPerView: 4,
    loop: true,
    freeMode: true,
    loopedSlides: 5, //looped slides should be the same
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
  });
}
// ---------------------------------------------
// Gallery Swiper (_partials/entry/gallerySlider.twig) // Desktop Only
const gallerySwiperEl = document.querySelector(".Gallery-Slider");
if (gallerySwiperEl !== null || gallerySwiperEl !== undefined) {
  const galleryloop = gallerySwiperEl?.dataset.loop == 1 ? true : false;
  const gallerySwiper = new Swiper(".Gallery-Slider", {
    direction: "horizontal",
    loop: galleryloop,
    slidesPerView: 1,

    thumbs: {
      swiper: thumbsSwiper,
    },
    navigation: {
      nextEl: ".gallery-slider-button-next",
      prevEl: ".gallery-slider-button-prev",
    },
  });
}

// --------------------------------------------------------------------------------------------------
